import { Component, Input } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Constant } from 'src/app/constants/constant';
import { Product } from 'src/app/models/app.models';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';
import { Thumbs } from 'swiper';

@Component({
  selector: 'calsoft-tab-layout',
  templateUrl: './tab-layout.component.html',
  styleUrls: ['./tab-layout.component.scss']
})
export class TabLayoutComponent {
  @Input() item: any;

  @Input() data: any;

  @Input() categoryId: any;
  @Input() header: any;
  @Input() id: any;
  @Input() productImageHeight:any;
  @Input()  showCartIcon : any;
  @Input()  showCartIncremented : any;
  
  @Input() showConfigurableDropdown: any;
  @Input() urlNavigation: any=true;
  @Input() heightMobile: any;
  @Input() widthMobile: any;
  @Input() sortOrder: any;
  @Input() other: any;
  @Input() type: any="home";
  @Input() height: any;
  @Input() width: any;
  @Input() imageType: any; 
  @Input() slidesPerView: any; 
  

  public youtubeUrl = environment.youtubeUrl;

  public serverPath1 = environment.commonImageApi + '/commonSlider/';

  public serverPath = environment.commonImageApi + '/home/';
  defaultIndex: number;

  constructor( private commonService: CommonService,) { }

  index: number=0;

  showSecondDiv: boolean = false;

  toggleSecondDiv(index: number) {
   this.defaultIndex=index;
  }

  ngOnInit() {

    this.defaultIndex=0;
    this.toggleSecondDiv( this.defaultIndex);
    if (this.item && this.item.customfield10) {
      document.documentElement.style.setProperty('--custom-color', this.item.customfield10);
    }
    
  }
  
  onTabChange(event: MatTabChangeEvent,index): void {
   
    this.index=event.index
  }

  

  goToCategories(id: any) {
    if(id>0){
      this.commonService.goToCategory(1, parseInt(id));
    }

  }
  

  link(url) {

    this.commonService.goToLink(url)
  }

	active: string;

  scrollDown(value: string, index: number) {
    this.active = value;
    this.defaultIndex = index; // Set the clicked button's index as the defaultIndex
    this.ScrollIntoView(this.active);
  }
  
	ScrollIntoView(elem: string) {
		this.active = "#" + elem;
		const ele = document.querySelector(this.active) as HTMLElement;
	  
		if (ele) {
		  const offsetTop = ele.getBoundingClientRect().top + window.pageYOffset;
		  const paddingTop = 300;
		  window.scrollTo({
			top: offsetTop - paddingTop,
			behavior: 'smooth'
		  });
		} else {
		  console.error('Element not found:', this.active);
		}
	  }


}

  
