
<div style="  height:auto;">
  <div class="container-one" [style.background-color]="item.customField35" [style.color]="item.customField36"  fxLayout="column" fxHide fxShow.gt-sm>
    <div class="inner-space">
      <div fxLayout="row" fxLayoutAlign="start start">
        <div class="main-heading-container" >
          <div >
            <h2  class="main-heading" [style.color]="item.customField36"> {{item.title}} <br> <span [style.color]="item.customField37"><span>{{item.customField39}}</span></span> </h2>
  
          </div>
          <a (click)="link(item.customField38)" class="view-more-button group">
            <span class="button-text" [style.color]="item.customField36">View More</span>
            <div class="img-container">
              <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyNSAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwLjk3NTQgNy45NDQ4SDIwLjQyNDRDMTQuMTI2NyA3Ljk0NDggNy44MjkwOCA3Ljk0NDggMS41MzE0NCA3Ljk0NDhDMS4zNTYxNyA3Ljk1MDA5IDEuMTgwNzYgNy45NDIwNyAxLjAwNjY0IDcuOTIwNzhDMC43ODk4NTggNy44ODQzNSAwLjU5MjY5MiA3Ljc2OTQyIDAuNDUwNDAzIDcuNTk2NTNDMC4zMDgxMTMgNy40MjM2NSAwLjIyOTk4IDcuMjA0MSAwLjIyOTk4IDYuOTc3MTRDMC4yMjk5OCA2Ljc1MDE5IDAuMzA4MTEzIDYuNTMwNjMgMC40NTA0MDMgNi4zNTc3NUMwLjU5MjY5MiA2LjE4NDg3IDAuNzg5ODU4IDYuMDY5OTMgMS4wMDY2NCA2LjAzMzUxQzEuMTgwNzMgNi4wMTE4MyAxLjM1NjE3IDYuMDAzODEgMS41MzE0NCA2LjAwOTQ5SDIwLjg5MTRDMjAuOTE1NCA1Ljk2NzY1IDIwLjkzOTQgNS45MjU4MSAyMC45NjY0IDUuODg0NzVDMjAuODE2NSA1Ljc1NjE1IDIwLjY1NjggNS42MzkxNiAyMC41MTY2IDUuNDk3MzhDMTkuNTkyOSA0LjU1Mzc0IDE4LjY3MjggMy42MDY0OSAxNy43NTYxIDIuNjU1NjJDMTcuNjAzOSAyLjUwODIgMTcuNTAyNyAyLjMxMzM1IDE3LjQ2NzcgMi4xMDA4NUMxNy40MzI4IDEuODg4MzQgMTcuNDY2MSAxLjY2OTg2IDE3LjU2MjcgMS40Nzg3OUMxNy42NTQ2IDEuMjkyOTQgMTcuODA0OSAxLjE0NDg0IDE3Ljk4ODkgMS4wNTg3MUMxOC4xNzI5IDAuOTcyNTgxIDE4LjM3OTcgMC45NTM1MiAxOC41NzU1IDEuMDA0NjVDMTguNzY1NyAxLjA1NzUxIDE4LjkzOTggMS4xNTkzMyAxOS4wODE2IDEuMzAwNkMyMC42OTM1IDIuOTM5OTYgMjIuMjk4OSA0LjU4NTUxIDIzLjg5NzggNi4yMzcyNkMyNC4zNDMxIDYuNjk2NjkgMjQuMzQ3NiA3LjI0NDQzIDIzLjg5NzggNy43MDkyN0MyMi4yOTA0IDkuMzcxMzYgMjAuNjc3IDExLjAyNyAxOS4wNTc2IDEyLjY3NjJDMTguNjYxIDEzLjA4MDYgMTguMDc3NyAxMy4wNzI4IDE3LjcxNzkgMTIuNjg5M0MxNy4zNTggMTIuMzA1OCAxNy4zNjQgMTEuNzA3NyAxNy43NTM5IDExLjI5NDhDMTguNzk3NSAxMC4yMDQ3IDE5Ljg0NzggOS4xMTg1MyAyMC45NzU0IDcuOTQ0OFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=" class=" default-img block group-hover:hidden w-5 lg:w-full" alt="link arrow">
              <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyNSAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwLjg2MjYgNy44MjM3SDIwLjMxMTZDMTQuMDEzOSA3LjgyMzcgNy43MTYyOCA3LjgyMzcgMS40MTg2NSA3LjgyMzdDMS4yNDMzOCA3LjgyOSAxLjA2Nzk3IDcuODIwOTcgMC44OTM4NDMgNy43OTk2OUMwLjY3NzA2NSA3Ljc2MzI2IDAuNDc5OSA3LjY0ODMyIDAuMzM3NjEgNy40NzU0NEMwLjE5NTMyIDcuMzAyNTYgMC4xMTcxODggNy4wODMgMC4xMTcxODggNi44NTYwNUMwLjExNzE4OCA2LjYyOTA5IDAuMTk1MzIgNi40MDk1NCAwLjMzNzYxIDYuMjM2NjZDMC40Nzk5IDYuMDYzNzcgMC42NzcwNjUgNS45NDg4NCAwLjg5Mzg0MyA1LjkxMjQxQzEuMDY3OTQgNS44OTA3NCAxLjI0MzM4IDUuODgyNzEgMS40MTg2NSA1Ljg4ODM5SDIwLjc3ODZDMjAuODAyNiA1Ljg0NjU2IDIwLjgyNjYgNS44MDQ3MiAyMC44NTM2IDUuNzYzNjZDMjAuNzAzNyA1LjYzNTA1IDIwLjU0NCA1LjUxODA3IDIwLjQwMzggNS4zNzYyOUMxOS40ODAxIDQuNDMyNjUgMTguNTYgMy40ODU0IDE3LjY0MzMgMi41MzQ1M0MxNy40OTExIDIuMzg3MSAxNy4zODk5IDIuMTkyMjYgMTcuMzU0OSAxLjk3OTc1QzE3LjMyIDEuNzY3MjUgMTcuMzUzNCAxLjU0ODc3IDE3LjQ0OTkgMS4zNTc2OUMxNy41NDE4IDEuMTcxODUgMTcuNjkyMSAxLjAyMzc1IDE3Ljg3NjEgMC45Mzc2MTdDMTguMDYwMSAwLjg1MTQ4NyAxOC4yNjY5IDAuODMyNDI3IDE4LjQ2MjggMC44ODM1NTJDMTguNjUyOSAwLjkzNjQxNyAxOC44MjcgMS4wMzgyNCAxOC45Njg4IDEuMTc5NUMyMC41ODA3IDIuODE4ODYgMjIuMTg2MSA0LjQ2NDQyIDIzLjc4NSA2LjExNjE3QzI0LjIzMDMgNi41NzU1OSAyNC4yMzQ4IDcuMTIzMzMgMjMuNzg1IDcuNTg4MThDMjIuMTc3NiA5LjI1MDI2IDIwLjU2NDIgMTAuOTA1OSAxOC45NDQ4IDEyLjU1NTFDMTguNTQ4MiAxMi45NTk1IDE3Ljk2NDkgMTIuOTUxNyAxNy42MDUxIDEyLjU2ODJDMTcuMjQ1MiAxMi4xODQ3IDE3LjI1MTIgMTEuNTg2NiAxNy42NDExIDExLjE3MzdDMTguNjg0NyAxMC4wODM2IDE5LjczNSA4Ljk5NzQ0IDIwLjg2MjYgNy44MjM3WiIgZmlsbD0iIzJFRDA2RSIvPgo8L3N2Zz4K" class=" hover-img hidden group-hover:block w-5 lg:w-full" alt="link arrow">
            </div>
          </a>
  
  
  
        </div>
  
      </div>
      <div fxLayout="row">
        <!-- Left Section -->
        <div class="content-two" #scrollContainer (scroll)="onScroll()" fxLayout="row">
          <!-- <div>
            <div class="progress-container">
              <div class="progress-bar" #progressBar></div>
            </div>
          </div> -->
          <div fxLayout="column" style="padding-left: 3rem;">
           
              <div *ngFor="let section of images; let i = index" class="item" #sectionItem>
                <div class="section" fxLayout.lt-md="column">
                  <div class="content-container">
                    <div class="count-section" [style.color]="item.customField36">
                      {{ index + 1 }}/{{ images.length }}
                    </div>
                    <div class="text-content" fxLayout="column">
                      <span class="section-title-two" [style.color]="item.customField37">
                        <b> {{section.customField6}}</b>
                        <!-- <calsoft-text-editor [data]="data" [text]="section.customField6"
                        [ngStyle.gt-md]="{'font-size': section.customField27 }">
                      </calsoft-text-editor> -->
                    </span>
                    <span class="description" [style.color]="item.customField36">
                      <span> {{section.customField7}}</span>
                      <!-- <calsoft-text-editor [data]="data" [text]="section.customField7"
                      [ngStyle.gt-md]="{'font-size': section.customField29 }">
                    </calsoft-text-editor> -->
                  </span>
                </div>
              </div>
            </div>
          </div>
   
      </div>
  
  
    </div>
  
    <div class="left-section" fxFlex="40">
  
      <div fxLayout="row" fxLayoutAlign="center" class="centered-content">
        <div fxLayout="column" class="sticky-content">
          <div class="rounded-container">
          
              <img  *ngIf="prevImage"
                [attr.height]="prevImage.customField23"
                [attr.width]="prevImage.customField24"
                [src]="serverPath1 + prevImage.customField8"
                [alt]="prevImage?.alt"
                class="sliding-image prev-image"
                />
       
  
              <img  *ngIf="currentImage"
                [attr.height]="currentImage.customField23"
                [attr.width]="currentImage.customField24"
                [src]="serverPath1 + currentImage.customField8"
                [alt]="currentImage?.alt"
                class="sliding-image current-image"
                />
   
          </div>
        </div>
      </div>
    </div>
  
    <!-- Right Section -->
  </div>
  </div>
  
  
  
  </div>
  <div class="container-two" [style.background-color]="item.customField35" [style.color]="item.customField36" fxLayout="column" fxHide fxShow.lt-md>
    <div fxLayout="row" fxLayoutAlign="start start" style="padding-top: 4rem;">
      <div class="main-heading-container">
        <div >
          <h2  class="main-heading" [style.color]="item.customField36"> {{item.title}} <br> <span [style.color]="item.customField37"><span>{{item.customField39}}</span></span> </h2>
  
        </div>
        <a (click)="link(item.customField38)" class="view-more-button group">
          <span class="button-text" [style.color]="item.customField36">View More</span>
          <div class="img-container">
            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyNSAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwLjk3NTQgNy45NDQ4SDIwLjQyNDRDMTQuMTI2NyA3Ljk0NDggNy44MjkwOCA3Ljk0NDggMS41MzE0NCA3Ljk0NDhDMS4zNTYxNyA3Ljk1MDA5IDEuMTgwNzYgNy45NDIwNyAxLjAwNjY0IDcuOTIwNzhDMC43ODk4NTggNy44ODQzNSAwLjU5MjY5MiA3Ljc2OTQyIDAuNDUwNDAzIDcuNTk2NTNDMC4zMDgxMTMgNy40MjM2NSAwLjIyOTk4IDcuMjA0MSAwLjIyOTk4IDYuOTc3MTRDMC4yMjk5OCA2Ljc1MDE5IDAuMzA4MTEzIDYuNTMwNjMgMC40NTA0MDMgNi4zNTc3NUMwLjU5MjY5MiA2LjE4NDg3IDAuNzg5ODU4IDYuMDY5OTMgMS4wMDY2NCA2LjAzMzUxQzEuMTgwNzMgNi4wMTE4MyAxLjM1NjE3IDYuMDAzODEgMS41MzE0NCA2LjAwOTQ5SDIwLjg5MTRDMjAuOTE1NCA1Ljk2NzY1IDIwLjkzOTQgNS45MjU4MSAyMC45NjY0IDUuODg0NzVDMjAuODE2NSA1Ljc1NjE1IDIwLjY1NjggNS42MzkxNiAyMC41MTY2IDUuNDk3MzhDMTkuNTkyOSA0LjU1Mzc0IDE4LjY3MjggMy42MDY0OSAxNy43NTYxIDIuNjU1NjJDMTcuNjAzOSAyLjUwODIgMTcuNTAyNyAyLjMxMzM1IDE3LjQ2NzcgMi4xMDA4NUMxNy40MzI4IDEuODg4MzQgMTcuNDY2MSAxLjY2OTg2IDE3LjU2MjcgMS40Nzg3OUMxNy42NTQ2IDEuMjkyOTQgMTcuODA0OSAxLjE0NDg0IDE3Ljk4ODkgMS4wNTg3MUMxOC4xNzI5IDAuOTcyNTgxIDE4LjM3OTcgMC45NTM1MiAxOC41NzU1IDEuMDA0NjVDMTguNzY1NyAxLjA1NzUxIDE4LjkzOTggMS4xNTkzMyAxOS4wODE2IDEuMzAwNkMyMC42OTM1IDIuOTM5OTYgMjIuMjk4OSA0LjU4NTUxIDIzLjg5NzggNi4yMzcyNkMyNC4zNDMxIDYuNjk2NjkgMjQuMzQ3NiA3LjI0NDQzIDIzLjg5NzggNy43MDkyN0MyMi4yOTA0IDkuMzcxMzYgMjAuNjc3IDExLjAyNyAxOS4wNTc2IDEyLjY3NjJDMTguNjYxIDEzLjA4MDYgMTguMDc3NyAxMy4wNzI4IDE3LjcxNzkgMTIuNjg5M0MxNy4zNTggMTIuMzA1OCAxNy4zNjQgMTEuNzA3NyAxNy43NTM5IDExLjI5NDhDMTguNzk3NSAxMC4yMDQ3IDE5Ljg0NzggOS4xMTg1MyAyMC45NzU0IDcuOTQ0OFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=" class=" default-img block group-hover:hidden w-5 lg:w-full" alt="link arrow">
            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyNSAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwLjg2MjYgNy44MjM3SDIwLjMxMTZDMTQuMDEzOSA3LjgyMzcgNy43MTYyOCA3LjgyMzcgMS40MTg2NSA3LjgyMzdDMS4yNDMzOCA3LjgyOSAxLjA2Nzk3IDcuODIwOTcgMC44OTM4NDMgNy43OTk2OUMwLjY3NzA2NSA3Ljc2MzI2IDAuNDc5OSA3LjY0ODMyIDAuMzM3NjEgNy40NzU0NEMwLjE5NTMyIDcuMzAyNTYgMC4xMTcxODggNy4wODMgMC4xMTcxODggNi44NTYwNUMwLjExNzE4OCA2LjYyOTA5IDAuMTk1MzIgNi40MDk1NCAwLjMzNzYxIDYuMjM2NjZDMC40Nzk5IDYuMDYzNzcgMC42NzcwNjUgNS45NDg4NCAwLjg5Mzg0MyA1LjkxMjQxQzEuMDY3OTQgNS44OTA3NCAxLjI0MzM4IDUuODgyNzEgMS40MTg2NSA1Ljg4ODM5SDIwLjc3ODZDMjAuODAyNiA1Ljg0NjU2IDIwLjgyNjYgNS44MDQ3MiAyMC44NTM2IDUuNzYzNjZDMjAuNzAzNyA1LjYzNTA1IDIwLjU0NCA1LjUxODA3IDIwLjQwMzggNS4zNzYyOUMxOS40ODAxIDQuNDMyNjUgMTguNTYgMy40ODU0IDE3LjY0MzMgMi41MzQ1M0MxNy40OTExIDIuMzg3MSAxNy4zODk5IDIuMTkyMjYgMTcuMzU0OSAxLjk3OTc1QzE3LjMyIDEuNzY3MjUgMTcuMzUzNCAxLjU0ODc3IDE3LjQ0OTkgMS4zNTc2OUMxNy41NDE4IDEuMTcxODUgMTcuNjkyMSAxLjAyMzc1IDE3Ljg3NjEgMC45Mzc2MTdDMTguMDYwMSAwLjg1MTQ4NyAxOC4yNjY5IDAuODMyNDI3IDE4LjQ2MjggMC44ODM1NTJDMTguNjUyOSAwLjkzNjQxNyAxOC44MjcgMS4wMzgyNCAxOC45Njg4IDEuMTc5NUMyMC41ODA3IDIuODE4ODYgMjIuMTg2MSA0LjQ2NDQyIDIzLjc4NSA2LjExNjE3QzI0LjIzMDMgNi41NzU1OSAyNC4yMzQ4IDcuMTIzMzMgMjMuNzg1IDcuNTg4MThDMjIuMTc3NiA5LjI1MDI2IDIwLjU2NDIgMTAuOTA1OSAxOC45NDQ4IDEyLjU1NTFDMTguNTQ4MiAxMi45NTk1IDE3Ljk2NDkgMTIuOTUxNyAxNy42MDUxIDEyLjU2ODJDMTcuMjQ1MiAxMi4xODQ3IDE3LjI1MTIgMTEuNTg2NiAxNy42NDExIDExLjE3MzdDMTguNjg0NyAxMC4wODM2IDE5LjczNSA4Ljk5NzQ0IDIwLjg2MjYgNy44MjM3WiIgZmlsbD0iIzJFRDA2RSIvPgo8L3N2Zz4K" class=" hover-img hidden group-hover:block w-5 lg:w-full" alt="link arrow">
          </div>
        </a>
      </div>
    </div>
  
  
    <div class="scroll-wrapper" #scrollContainerTwo>
      <div class="scroll-content" fxLayout="row">
     
          <div *ngFor="let section of images; let i = index" class="scroll-item" fxLayout="column" fxLayoutAlign="start start">
            <div class="image-container" fxLayout="row" fxLayoutAlign="center center">
              <img style="border-radius: 9999px;" [attr.height]="section.customField23"
                [attr.width]="section.customField24"
                [src]="serverPath1 + section.customField8"
                [alt]="section?.alt"
                class="object-cover rounded-full" />
              </div>
              <div class="title-container" [style.color]="item.customField37">
                {{section.customField6}}
                <!-- <calsoft-text-editor [data]="data" [text]="section.customField6"
                [ngStyle.gt-md]="{'font-size': item.customField27 }">
              </calsoft-text-editor> -->
            </div>
            <div class="description" [style.color]="item.customField36">
              {{section.customField7}}
              <!-- <calsoft-text-editor [data]="data" [text]="section.customField7"
              [ngStyle.gt-md]="{'font-size': item.customField29 }">
            </calsoft-text-editor> -->
          </div>
        </div>

    </div>
  </div>
  </div>
  
</div>

