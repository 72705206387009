import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-freetext-banner',
  templateUrl: './freetext-banner.component.html',
  styleUrls: ['./freetext-banner.component.scss']
})
export class FreetextBannerComponent {
  public serverPath = environment.commonImageApi + '/home/';
  @Input() data: any;
  @Input() item: any;
  @Input() images: any;
    constructor(private commonService: CommonService){}
  goToLocation(url) {

    this.commonService.goToLink(url)
   
  }
}
