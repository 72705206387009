import { NgModule } from '@angular/core';
import { CommonModule, getLocaleDayNames } from '@angular/common';
import { LayoutRoutingModule } from './layout-routing.module';
import { LayoutComponent } from './layout.component';
import { BannerContentComponent } from './banner-content/banner-content.component';
import { BannertextComponent } from './bannertext/bannertext.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { HomeBannerLayoutComponent } from './home-banner-layout/home-banner-layout.component';
import { HomeLayoutsComponent } from './home-layouts/home-layouts.component';
import { MapsComponentComponent } from './maps-component/maps-component.component';
import { TextwithimageComponent } from './textwithimage/textwithimage.component';
import { CategoriesModule } from '../categories/categories.module';
import { MatIconModule } from '@angular/material/icon';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SharedModule } from 'src/app/shared/shared.module';
import { DirectiveModule } from 'src/app/shared/utils/directive/directive.module';
import { PipesModule } from 'src/app/theme/pipes/pipes.module';
import { ProductLayoutModule } from 'src/app/theme/product-layout/product-layout.module';
import { SliderModule } from 'src/app/theme/slider/slider.module';
import { SwiperModule } from 'src/app/theme/swiper/swiper.module';
import { BrandsModule } from '../home/brands/brands.module';
import { ContentAnimDirective } from 'src/app/theme/product-layout/directives/content-anim.directive';
import { NgOptimizedImage } from '@angular/common';
import { BlogSectionModule } from '../home/blog-section/blog-section.module';
import { YoutubeVideoComponent } from './youtube-video/youtube-video.component';
import { DynamicComponentModule } from 'src/app/shared/component/dynamic-component.module';
import { FaqComponent } from './faq/faq.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SlidebannerComponent } from './slidebanner/slidebanner.component';
import { FreetextComponent } from './freetext/freetext.component';
import { CategoryCarouselComponent } from './category-carousel/category-carousel/category-carousel.component';
import { DirectionViewComponent } from './direction-view/direction-view.component';
import { VedioBannerComponent } from './vedio-banner/vedio-banner.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ImageOverContentComponent } from './image-over-content/image-over-content.component';
import { Mp4Component } from './mp4/mp4.component';
import { PlanCompareComponent } from './plan-compare/plan-compare.component';
import { VedioLayoutComponent } from './vedio-layout/vedio-layout.component';
import { ImageCardComponent } from './image-card/image-card.component';
import { StoreLocationComponent } from './store-location/store-location.component';
import { TabLayoutComponent } from './tab-layout/tab-layout.component';
import { BannerOverContentComponent } from './banner-over-content/banner-over-content.component';
import { CustomerreviewsComponent } from './customerreviews/customerreviews.component';
import { NewMenuComponent } from './new-menu/new-menu.component';
import { HomeBannerLayout2Component } from './home-banner-layout2/home-banner-layout2.component';
import { CarouselWithProductComponent } from './carousel-with-product/carousel-with-product.component';
import { TopSellinglayoutComponent } from './top-sellinglayout/top-sellinglayout.component';
import { NewMenuSubComponent } from './new-menu-sub/new-menu-sub.component';
import { CategorywithimageComponent } from './categorywithimage/categorywithimage.component';
import { CategoryTabLayoutComponent } from './category-tab-layout/category-tab-layout.component';
import { NgOptimizedImageComponent } from './ng-optimized-image/ng-optimized-image.component';
import { LookbackComponent } from './lookback/lookback.component';
import { Categorywithimage2Component } from './categorywithimage/categorywithimage2/categorywithimage2.component';
import { LookbackSubComponent } from './lookback/lookback-sub/lookback-sub.component';
import { TopSellingLayoutProductChildComponent } from './top-sellinglayout/top-selling-layout-product-child/top-selling-layout-product-child.component';
import { ImageTextWithButtonComponent } from './image-text-with-button/image-text-with-button.component';
import { ImagewidgetComponent } from './imagewidget/imagewidget.component';
import { BannerimageTextComponent } from './bannerimage-text/bannerimage-text.component';
import { BannerCarouselTextComponent } from './banner-carousel-text/banner-carousel-text.component';
import { MobileiconComponent } from './mobileicon/mobileicon.component';
import { ImageTextComponent } from './image-text/image-text.component';
import { SearchLayoutComponent } from './search-layout/search-layout.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TypeFormComponent } from './type-form/type-form.component';
import { PriceChartViewComponent } from './price-chart-view/price-chart-view.component';
import { ComponentModule } from 'src/app/theme/components/component.module';
import { StickyMenuLeftComponent } from './mp4/sticky-menu-left/sticky-menu-left.component';
import { Loopbacksub1Component } from './lookback/loopbacksub1/loopbacksub1.component';
import { ServiceLayoutComponent } from './service-layout/service-layout.component';
import { StepflowLayoutComponent } from './stepflow-layout/stepflow-layout.component';
import { ButtonType1Component } from './button-type1/button-type1.component';
import { ImageContactFormComponent } from './image-contact-form/image-contact-form.component';
import { ButtonRowHeaderComponent } from './button-row-header/button-row-header.component';
import { ButtonType2Component } from './button-type2/button-type2.component';
import { TextWithTextComponent } from './text-with-text/text-with-text.component';
import { ImageTextRowComponent } from './image-text-row/image-text-row.component';
import { PlanCompareType2Component } from './plan-compare-type2/plan-compare-type2.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ServiceContactButtonComponent } from './service-contact-button/service-contact-button.component';
import { ScrollImageComponent } from './scroll-image/scroll-image.component';
import { ImagecolHoverComponent } from './imagecol-hover/imagecol-hover.component';
import { EditorTextComponent } from './editor-text/editor-text.component';
import { NewBannerComponent } from './new-banner/new-banner.component';
import { DevelopmentservicesComponent } from './developmentservices/developmentservices.component';
import { MobileIcon2Component } from './mobileicon/mobile-icon2/mobile-icon2.component';
import { PhoneNumberModule } from '../phone-number/phone-number.module';
import { SpeechtextComponent } from './speechtext/speechtext.component';
import { VedioBannerSearchComponent } from './vedio-banner-search/vedio-banner-search.component';
import { CarouselSlideComponent } from './carousel-slide/carousel-slide.component';
import { ServicePlanComponent } from './service-plan/service-plan.component';
import { ControlsModule } from 'src/app/theme/controls/controls.module';
import { BannerSearchComponent } from './banner-search/banner-search.component';
import { SlideImageWithTextComponent } from './slide-image-with-text/slide-image-with-text.component';
import { FormPopupComponent } from './form-popup/form-popup.component';
import { CategoryListsComponent } from './category-lists/category-lists.component';
import { PricePlanComponent } from './price-plan/price-plan.component';
import { BookingSearchBoxComponent } from './booking-search-box/booking-search-box.component';
import { ImageContentNewComponent } from './image-content-new/image-content-new.component';
import { ImageZigzagTextComponent } from './image-zigzag-text/image-zigzag-text.component';
import { ImageCarouselTwoHoverComponent } from './image-carousel-two-hover/image-carousel-two-hover.component';
import { MultipleImagetextComponent } from './multiple-imagetext/multiple-imagetext.component';
import { ImageWithTextTypeComponent } from './image-with-text-type/image-with-text-type.component';
import { BannerTypeAnimComponent } from './banner-type-anim/banner-type-anim.component';
import { EditorTextChildComponent } from './editor-text/editor-text-child/editor-text-child.component';
import { PartnersComponent } from './partners/partners.component';
import { ImageType10Component } from './image-type10/image-type10.component';
import { SearchBox1Component } from './search-box1/search-box1.component';
import { ImageCircleProfileComponent } from './image-circle-profile/image-circle-profile.component';
import { BannerAnimeComponent } from './banner-anime/banner-anime.component';
import { ImagescrollingComponent } from './imagescrolling/imagescrolling.component';
import { PlanCompareType3Component } from './plan-compare-type3/plan-compare-type3.component';
import { ImageItemListComponent } from './image-item-list/image-item-list.component';
import { AnimeImageComponent } from './anime-image/anime-image.component';
import { CourseContentComponent } from './course-content/course-content.component';
import { VideoPageComponent } from './video-page/video-page.component';
import { TextAnimationComponent } from './text-animation/text-animation.component';
import { CardSlidePopupComponent } from './card-slide-popup/card-slide-popup.component';
import { PlanCompareType4Component } from './plan-compare-type4/plan-compare-type4.component';
import { PartnerLayoutComponent } from './partner-layout/partner-layout.component';
import { PartnerSectionComponent } from './partner-section/partner-section.component';
import { PartnerDetailComponent } from './partner-detail/partner-detail.component';
import { FaciliteisImageComponent } from './faciliteis-image/faciliteis-image.component';
import { ButtontypesComponent } from './buttontypes/buttontypes.component';
import { CourseimageComponent } from './courseimage/courseimage.component';
import { NewsletterAds2Component } from './newsletter-ads2/newsletter-ads2.component';
import { StepFormComponent } from './step-form/step-form.component';
import { StepFormMainComponent } from './step-form/step-form-main/step-form-main.component';
import { MatStepperModule } from '@angular/material/stepper';
import { KanbanformComponent } from './kanbanform/kanbanform.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SubscriptionModule } from 'src/app/shared/subscription/subscription.module';
import { SubscriptionPriceComponent } from './subscription-price/subscription-price.component';
import { ImageCheckboxComponent } from './image-checkbox/image-checkbox.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonLoginComponent } from './common-login/common-login.component';
import { SliderautoComponent } from './sliderauto/sliderauto.component';
import { IconflowComponent } from './iconflow/iconflow.component';
import { MatDividerModule } from '@angular/material/divider';
import { BannerimageText2Component } from './bannerimage-text2/bannerimage-text2.component';
import { RateStarComponent } from './rate-star/rate-star.component';
import { PyramidimageComponent } from './pyramidimage/pyramidimage.component';
import { PlanCompareType5Component } from './plan-compare-type5/plan-compare-type5.component';
import { PdfBannerComponent } from './pdf-banner/pdf-banner.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Textanimation2Component } from './textanimation2/textanimation2.component';
import { ImagecolHover2Component } from './imagecol-hover2/imagecol-hover2.component';
import { AnimatedTextComponent } from './animated-text/animated-text.component';
import { FooterlayoutComponent } from './footerlayout/footerlayout.component';
import { EmiCalculatorModule } from '../emi-calculator/emi-calculator.module';
import { IconwithtextComponent } from './iconwithtext/iconwithtext.component';
import { ImagecircleanimateComponent } from './imagecircleanimate/imagecircleanimate.component';
import { TypingBannerComponent } from './typing-banner/typing-banner.component';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { TextwithformPopupComponent } from './textwithform-popup/textwithform-popup.component';
import { ImageOverContent2Component } from './image-over-content2/image-over-content2.component';
import { LiveRateComponent } from './live-rate/live-rate.component';
import { NgOptimizedImage1Component } from './ng-optimized-image1/ng-optimized-image1.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BennerwithloginComponent } from './bennerwithlogin/bennerwithlogin.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { BrandsCarouselType26Component } from './brands-carousel-type26/brands-carousel-type26.component';
import { PlanCompareType10Component } from './plan-compare-type10/plan-compare-type10.component';
import { ButtonType6Component } from './button-type6/button-type6.component';
import { BannertextwithimageComponent } from './bannertextwithimage/bannertextwithimage.component';
import { BrandscarouselType27Component } from './brandscarousel-type27/brandscarousel-type27.component';
import { ImageslideranimationComponent } from './imageslideranimation/imageslideranimation.component';
import { Leftmenu1Component } from './leftmenu1/leftmenu1.component';
import { PlanCompareType11Component } from './plan-compare-type11/plan-compare-type11.component';
import { DoubleimageformComponent } from './doubleimageform/doubleimageform.component';
import { TexthoverwithimageComponent } from './texthoverwithimage/texthoverwithimage.component';
import { PlanCompareType12Component } from './plan-compare-type12/plan-compare-type12.component';
import { CouponModule } from '../coupon/coupon.module';
import { AnimatedScrollModule } from './animated-scroll/animated-scroll.module';
import { AnimatedScrollComponent } from './animated-scroll/animated-scroll.component';
import { PyramidtextModule } from './pyramidtext/pyramidtext.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CalsoftScrollbarModule } from 'src/app/shared/utils/directive/scrollbar';
import { TabLayout2Component } from './tab-layout2/tab-layout2.component';
import { Zigzagimage1Component } from './zigzagimage1/zigzagimage1.component';
import { AnimatedScrollReviewTwoComponent } from './animated-scroll-review-two/animated-scroll-review-two.component';
import { AnimatedScrollReviewComponent } from './animated-scroll-review/animated-scroll-review.component';
import { AnimatedScrollTwoComponent } from './animated-scroll-two/animated-scroll-two.component';
import { MarqeedesignComponent } from './marqeedesign/marqeedesign.component';
import { FreetextBannerComponent } from './freetext-banner/freetext-banner.component';
import { ImageWithTextComponent } from './image-with-text/image-with-text.component';
import { CustomSideNavComponent } from './custom-side-nav/custom-side-nav.component';


@NgModule({
  declarations: [
    DoubleimageformComponent,
    ImageWithTextComponent,
    FreetextBannerComponent,
    LayoutComponent,
    BannerContentComponent,
    BannertextComponent,
    ContactFormComponent,
    HomeBannerLayoutComponent,
    HomeLayoutsComponent,
    MapsComponentComponent,
    TextwithimageComponent,
    ContentAnimDirective,
    YoutubeVideoComponent,
    FaqComponent,
    SlidebannerComponent,
    FreetextComponent,
    CategoryCarouselComponent,
    DirectionViewComponent,
    VedioBannerComponent,
    ImageOverContentComponent,
    Mp4Component,
    PlanCompareComponent,
    VedioLayoutComponent,
    ImageCardComponent,
    StoreLocationComponent,
    TabLayoutComponent,
    BannerOverContentComponent,
    CustomerreviewsComponent,
    NewMenuComponent,
    HomeBannerLayout2Component,
    CarouselWithProductComponent,
    TopSellinglayoutComponent,
    NewMenuSubComponent,
    CategorywithimageComponent,
    CategoryTabLayoutComponent,
    NgOptimizedImageComponent,
    LookbackComponent,
    Categorywithimage2Component,
    LookbackSubComponent,
    TopSellingLayoutProductChildComponent,
    ImageTextWithButtonComponent,
    ImagewidgetComponent,
    BannerimageTextComponent,
    BannerCarouselTextComponent,
    MobileiconComponent,
    ImageTextComponent,
    SearchLayoutComponent,
    TypeFormComponent,
    PriceChartViewComponent,
    StickyMenuLeftComponent,
    Loopbacksub1Component,
    ServiceLayoutComponent,
    StepflowLayoutComponent,
    ButtonType1Component,
    ImageContactFormComponent,
    ButtonRowHeaderComponent,
    ButtonType2Component,
    TextWithTextComponent,
    ImageTextRowComponent,
    PlanCompareType2Component,
    ServiceContactButtonComponent,
    ScrollImageComponent,
    ImagecolHoverComponent,
    EditorTextComponent,
    NewBannerComponent,
    DevelopmentservicesComponent,
    MobileIcon2Component,
    SpeechtextComponent,
    VedioBannerSearchComponent,
    CarouselSlideComponent,
    ServicePlanComponent,
    BannerSearchComponent,
    SlideImageWithTextComponent,
    FormPopupComponent,
    CategoryListsComponent,
    PricePlanComponent,
    BookingSearchBoxComponent,
    ImageContentNewComponent,
    ImageZigzagTextComponent,
    ImageCarouselTwoHoverComponent,
    MultipleImagetextComponent,
    ImageWithTextTypeComponent,
    BannerTypeAnimComponent,
    EditorTextChildComponent,
    PartnersComponent,
    ImageType10Component,
    SearchBox1Component,
    ImageCircleProfileComponent,
    BannerAnimeComponent,
    ImagescrollingComponent,
    PlanCompareType3Component,
    ImageItemListComponent,
    AnimeImageComponent,
    CourseContentComponent,
    VideoPageComponent,
    TextAnimationComponent,
    CardSlidePopupComponent,
    PlanCompareType4Component,
    PartnerLayoutComponent,
    PartnerSectionComponent,
    PartnerDetailComponent,
    FaciliteisImageComponent,
    ButtontypesComponent,
    CourseimageComponent,
    NewsletterAds2Component,
    StepFormComponent,
    StepFormMainComponent,
    KanbanformComponent,
    SubscriptionPriceComponent,
    ImageCheckboxComponent,
    CommonLoginComponent,
    SliderautoComponent,
    IconflowComponent,
    BannerimageText2Component,
    RateStarComponent,
    PyramidimageComponent,
    TextAnimationComponent,
    PlanCompareType5Component,
    PdfBannerComponent,
    Textanimation2Component,
    ImagecolHover2Component,
    AnimatedTextComponent,
    FooterlayoutComponent,
    IconwithtextComponent,
    ImagecircleanimateComponent,
    TypingBannerComponent,
    TextwithformPopupComponent,
    ImageOverContent2Component,
    LiveRateComponent,
    NgOptimizedImage1Component,
    BennerwithloginComponent,
    CalculatorComponent,
    BrandsCarouselType26Component,
    PlanCompareType10Component,
    ButtonType6Component,
    BannertextwithimageComponent,
    BrandscarouselType27Component,
    ImageslideranimationComponent,
    Leftmenu1Component,
    PlanCompareType11Component,
    TexthoverwithimageComponent,
    PlanCompareType12Component,
    TabLayout2Component,
    Zigzagimage1Component,
    EditorTextComponent,
    AnimatedScrollTwoComponent,
    AnimatedScrollReviewTwoComponent,
    AnimatedScrollReviewComponent,
    MarqeedesignComponent,
    FreetextBannerComponent,
    ImageWithTextComponent,
    CustomSideNavComponent
  ],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    CategoriesModule,
    MatIconModule,
    CustomFlexLayoutModule,
    CommonModule,
    MatTabsModule,
    CustomFlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    InfiniteScrollModule,
    MatProgressSpinnerModule,
    DirectiveModule,
    SliderModule,
    ProductLayoutModule,
    PipesModule,
    ReactiveFormsModule,
    MatInputModule,
    SwiperModule,
    BrandsModule,
    CarouselModule,
    SharedModule,
    NgOptimizedImage,
    BlogSectionModule,
    DynamicComponentModule,
    MatExpansionModule,
    LazyLoadImageModule,
    NgOptimizedImage,
    MatAutocompleteModule,
    ComponentModule,
    MatSlideToggleModule,  
    PhoneNumberModule,
    MatStepperModule,
    DragDropModule,
    SubscriptionModule,
    MatDividerModule,
    PdfViewerModule,
    EmiCalculatorModule,
    NgxTypedJsModule,
    NgxMatSelectSearchModule,
    CouponModule,AnimatedScrollModule,
    PyramidtextModule,
    CalsoftScrollbarModule,
    


    
  ], exports:[LayoutComponent,EditorTextComponent,CustomSideNavComponent, DoubleimageformComponent,YoutubeVideoComponent,AnimatedScrollComponent, ContentAnimDirective, CarouselSlideComponent, FormPopupComponent, SpeechtextComponent]
})
export class LayoutModule { }
