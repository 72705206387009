<calsoft-editor-text [data]="data" [text]="item.customField7" [align]="item.customField34">
</calsoft-editor-text>
<div style="padding: 2rem;">
    <div class="image-container-main">
        <div class="image-container">
            <figure class="figure-container">
                <img [src]="serverPath + item.customField22" class="w-auto" loading="lazy">
            </figure>
           
        </div>
        <div class="text-container-main">
            <h3 class="main-heading">
                <calsoft-editor-text [data]="data"      [style.color]="item.customField25"
                                                            [text]="item?.customField10" 
                                                            [fontSize]="item.customField11"
                                                            [MobfontSize]="item.customField12">
                                                        </calsoft-editor-text> <br>
                <span class="main-animating-words"  [style.color]="item.customField26">{{displayedText}}</span>
                <span class="cursor text-primary">|</span>
            </h3>
            
            <p class="main-para">
                <calsoft-editor-text [data]="data" 
                [text]="item?.customField14" 
                [fontSize]="item.customField15"
                [MobfontSize]="item.customField16">
            </calsoft-editor-text> 
            </p>
            <div class="main-button">
                <span>
    <a (click)="goToLocation(item.customField23)" class="main-button-link" [style.color]="item.customField19" [style.background-color]="item.customField20">{{item.customField17}}</a>
                </span>
                <span>
                    <a (click)="goToLocation(item.customField24)" class="main-button-link" [style.color]="item.customField19" [style.background-color]="item.customField21">{{item.customField18}}</a>
    
                </span>
    
            </div>
    
        </div>
    </div>
</div>

