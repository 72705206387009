import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'calsoft-custom-side-nav',
  templateUrl: './custom-side-nav.component.html',
  styleUrls: ['./custom-side-nav.component.scss']
})
export class CustomSideNavComponent {

  @Input() isOpen: boolean = false;
  @Input() data: any;
  @Input() sideMenuItem: any;
  @Output() close = new EventEmitter<void>();

  closeSidebar(): void {
     this.close.emit();
  }

}
