<div class="lead-main-container">
    <div class="inner-container-one">
        <div class="row-container">
            <div class="text-main-container">
                <h1 class="heading-main-container">
                    <calsoft-editor-text [data]="data" 
                [text]="item?.customField10" 
                [fontSize]="item.customField11"
                [MobfontSize]="item.customField12">
            </calsoft-editor-text> 
                   
                </h1>
                <p class="para-main-container">
                    <calsoft-editor-text [data]="data" 
                    [text]="item?.customField14" 
                    [fontSize]="item.customField15"
                    [MobfontSize]="item.customField16">
                </calsoft-editor-text>                 </p>
                <div class="button-main-container">
                    <span class="button-one">
<a (click)="goToLocation(item.customField23)" class="link-button"  [style.color]="item.customField19" [style.background-color]="item.customField20">{{item.customField17}}</a>
                    </span>
                    <span class="button-one">
                        <a (click)="goToLocation(item.customField24)" class="link-button" [style.color]="item.customField19" [style.background-color]="item.customField21">{{item.customField17}}</a>
                    </span>

                </div>

            </div>

        </div>

    </div>
    <figure class="inner-container-two">
        <img [src]="serverPath + item.customField22" class="w-auto" loading="lazy">

    </figure>
</div>