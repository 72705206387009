inner canme
<div class="custom-sidebar" [class.open]="isOpen" [style.width]="'300px'">
    <div class="sidebar-header">
        <!-- <button (click)="closeSidebar()">
            <mat-icon>close</mat-icon>
        </button> -->
    </div>
    <mat-nav-list>
        <calsoft-side-bar-menu [data]="data" [sideMenuItem]="sideMenuItem"></calsoft-side-bar-menu>
    </mat-nav-list>
</div>
<div class="overlay" [class.open]="isOpen" (click)="closeSidebar()"></div>