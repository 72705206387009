import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-image-with-text',
  templateUrl: './image-with-text.component.html',
  styleUrls: ['./image-with-text.component.scss']
})
export class ImageWithTextComponent {
  words: string[] = ["Lead Management", "Sales Tracking", "Customer Engagement"];
  displayedText: string = "";
  wordIndex: number = 0;
  charIndex: number = 0;
  isDeleting: boolean = false;
  typingSpeed: number = 200; 
  deletingSpeed: number = 100; 
  pauseBeforeDelete: number = 1500; 
  pauseBeforeNextWord: number = 500; 
  public serverPath = environment.commonImageApi + '/home/';
  @Input() data: any;
  @Input() item: any;
  @Input() images: any;
     constructor(private commonService: CommonService){}
   goToLocation(url) {
 
     this.commonService.goToLink(url)
    
   }

  ngOnInit(): void {
    this.setWords();
    if (this.words.length > 0) {
      this.typeEffect();
    }
  }
  setWords(): void {
    if (this.item?.customfield22) {
      this.words = this.item.customfield22.split(',').map(word => word.trim()).filter(word => word.length > 0);
    }

    if (this.words.length === 0) {
      this.words = ["Default Text"]; // Fallback text if customfield22 is empty
    }
  }

  typeEffect(): void {
    let currentWord = this.words[this.wordIndex];

    if (this.isDeleting) {
      this.displayedText = currentWord.substring(0, this.charIndex--);
    } else {
      this.displayedText = currentWord.substring(0, this.charIndex++);
    }

    let timeoutSpeed = this.isDeleting ? this.deletingSpeed : this.typingSpeed;

    if (!this.isDeleting && this.charIndex === currentWord.length + 1) {
      this.isDeleting = true;
      setTimeout(() => this.typeEffect(), this.pauseBeforeDelete);
    } else if (this.isDeleting && this.charIndex === 0) {
      this.isDeleting = false;
      this.wordIndex = (this.wordIndex + 1) % this.words.length;
      setTimeout(() => this.typeEffect(), this.pauseBeforeNextWord);
    } else {
      setTimeout(() => this.typeEffect(), timeoutSpeed);
    }
  }
}
